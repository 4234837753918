import { useMemo } from 'react';

import { Address } from 'abitype';

import { EarnableTokensForStrategyResponse } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';
import { transformAddressesToRewardTokens } from '@/utils/rewardsUtils';

/**
 * This hook fetches the amount of earnable tokens for given strategies.
 *
 * @param {Address[]} strategyAddresses - An array of strategy addresses from which
 *                                        the earnable tokens will be fetched.
 *
 * @returns An object containing:
 * - `earnableTokens`: An array of earnable tokens for the specified strategies. Defaults to an empty array
 *                     if no data is fetched.
 * - `isEarnableTokensSuccess`: A boolean indicating whether the request was successful.
 * - `isEarnableTokensLoading`: A boolean indicating whether the request is currently loading.
 */

const useFetchEarnableForStrategy = (strategyAddresses: Address[]) => {
  // const { data, isSuccess, isLoading } =
  const { data } =
    api.rewards.getEarnableTokensForStrategy.useQuery<EarnableTokensForStrategyResponse>(
      strategyAddresses,
      {
        enabled: strategyAddresses.length > 0,
        staleTime: 300000,
        trpc: { context: { skipBatch: true } },
      },
    );

  const earnableTokens = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.rewards.map(({ strategyAddress, tokenAddresses }) => {
      const transformedRewardTokens = transformAddressesToRewardTokens(tokenAddresses);
      return {
        strategyAddress,
        tokenAddresses: transformedRewardTokens,
      };
    });
  }, [data]);

  return {
    earnableTokens,
    // isEarnableTokensSuccess: isSuccess,
    // isEarnableTokensLoading: isLoading,
    isEarnableTokensSuccess: true,
    isEarnableTokensLoading: false,
  };
};

export default useFetchEarnableForStrategy;
