'use client';

import { TextV2 } from '@layr-labs/eigen-kit/react';

import TokenTable from 'components/Token/TokenTable';

export default function RestakePage() {
  return (
    <div className="mb-4 flex flex-col gap-4">
      <TextV2 intent="DisplayS" weight="medium" className="text-blue-800">
        Restake
      </TextV2>
      <TokenTable page="restake" />
    </div>
  );
}
