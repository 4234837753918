'use client';

import { memo, useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import * as Dialog from '@radix-ui/react-dialog';
import { ColDef } from 'ag-grid-community';

import { TextV2, ValueLoader } from '@layr-labs/eigen-kit/react';
import { token } from '@layr-labs/eigen-kit/types';

import NameSymbolCell from 'components/Claims/NameSymbolCell';

const AgGridReactDynamic = dynamic(() => import('ag-grid-react').then((mod) => mod.AgGridReact), {
  ssr: false,
  loading: () => (
    <div className="mt-4 flex flex-col">
      <ValueLoader intent="TextL" className="mb-2 w-full" />
      <ValueLoader className="h-56" />
    </div>
  ),
});

type APRData = {
  apr: number;
  amountToRestake: bigint;
};

type RewardTokenModalProps = {
  headerTitle: string;
  aprData?: APRData;
  rewardTokenModalTableData: token[];
  isOpen: boolean;
  depositOrDelegateButton?: React.ReactNode;
  onClose: () => void;
};

const colDefs: ColDef<token, unknown>[] = [
  {
    field: 'name',
    headerName: 'Reward tokens',
    resizable: false,
    flex: 1,
    minWidth: 100,
    wrapHeaderText: true,
    suppressMovable: true,
    cellRenderer: (props) => (
      <NameSymbolCell
        icon={props.data.icon}
        name={props.data.name}
        tokenAddress={props.data.address}
        rewardsMode="claim"
        symbol={props.data.symbol}
      />
    ),
  },
];

function RewardTokenModal({
  headerTitle,
  aprData,
  rewardTokenModalTableData,
  depositOrDelegateButton,
  isOpen,
  onClose,
}: RewardTokenModalProps) {
  const [data, setData] = useState<token[]>();

  useEffect(() => {
    if (rewardTokenModalTableData) {
      setData(rewardTokenModalTableData);
    }
  }, [rewardTokenModalTableData]);

  const closeModal = useCallback(() => {
    onClose();
    // Note: isOpen is included in the dependency array above because onClose updates the state of isOpen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed top-0 z-10 flex h-screen w-screen items-center justify-center bg-black-200"
          onClick={closeModal}
        />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-20 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-4 rounded-lg bg-white outline-none sm:top-1/3 sm:mt-20 sm:h-[440px] sm:w-[400px]">
          <Dialog.Title className="flex w-full items-center justify-between px-4 pt-4">
            <TextV2 intent="TextL">{headerTitle}</TextV2>
          </Dialog.Title>
          {aprData && (
            <div className="flex flex-col items-center justify-center gap-2">
              <TextV2 intent="TextS" className="text-blue-800">
                APR: {aprData.apr}%
              </TextV2>
              <TextV2 intent="TextS" className="text-blue-800">
                Amount to restake: {aprData.amountToRestake}
              </TextV2>
            </div>
          )}
          <div className="ag-theme-quartz ag-theme-eigen h-full w-full px-4">
            <AgGridReactDynamic
              rowData={data}
              className="pb-6"
              domLayout="normal"
              rowClass="hover:bg-blue-100 cursor-pointer"
              columnDefs={colDefs}
              suppressRowHoverHighlight={true}
            />
          </div>
          {depositOrDelegateButton && (
            <div className="flex w-full items-center justify-between gap-4 px-4 pb-4">
              {depositOrDelegateButton}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

RewardTokenModal.displayName = 'RewardTokenModal';

export default memo(RewardTokenModal);
