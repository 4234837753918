import { datadogRum } from '@datadog/browser-rum';
import { Address } from 'abitype';

import { shortenAddress } from '@layr-labs/eigen-kit/util';
import { AVSReward, token, TokenReward, TokenSelection } from '@layr-labs/eigen-kit/types';

import { tokenAddressToToken } from '.';

export const transformEarnerAVSRewards = (earnerAVSRewards: AVSReward[]): TokenSelection[] => {
  if (!earnerAVSRewards) {
    return [];
  }
  const flatRewards = earnerAVSRewards.flatMap((reward) => reward.tokens);
  return tokenRewardsToTokenSelections(flatRewards);
};

export const tokenRewardsToTokenSelections = (tokenRewards: TokenReward[]): TokenSelection[] => {
  if (!tokenRewards) {
    return [];
  }
  return tokenRewards
    .map((reward) => {
      const token = tokenAddressToToken(reward.tokenAddress);
      if (!token) {
        return {
          name: shortenAddress(reward.tokenAddress),
          address: reward.tokenAddress,
          symbol: '',
          icon: '',
          weiAmount: reward.weiAmount,
        };
      }
      return {
        name: token.name,
        address: reward.tokenAddress,
        symbol: token.symbol,
        icon: token.icon,
        weiAmount: reward.weiAmount,
      };
    })
    .filter((token) => token.weiAmount > 0n);
};

export const tempAPRMax = (apr: number): boolean => {
  const isAPRLessThanThreshold = apr < 0.1;

  if (!isAPRLessThanThreshold) {
    datadogRum.addError({
      message: `APR is greater than 0.1: ${apr}`,
    });
  }

  return isAPRLessThanThreshold;
};

export const transformAddressesToRewardTokens = (addresses: Address[]): token[] => {
  return addresses.map((address) => {
    const token = tokenAddressToToken(address);
    if (!token) {
      return {
        name: shortenAddress(address),
        slug: '',
        address,
        types: ['reward'],
        strategyAddress: '0x',
        symbol: '',
        icon: '',
        decimals: 18,
        rebasing: false,
        apiID: '',
        group: 'token',
        usedForPoints: false,
        about: '',
      };
    }
    return token;
  });
};
