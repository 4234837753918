'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { ColDef, ColumnState, GridReadyEvent, IRowNode, RowSelectedEvent } from 'ag-grid-community';
import { useLocalStorage } from 'usehooks-ts';

import {
  ButtonV2,
  ComponentSize,
  PaginationV2,
  TextInput,
  TextV2,
  ValueLoader,
} from '@layr-labs/eigen-kit/react';
import { token, TokenTableData } from '@layr-labs/eigen-kit/types';

import TokenIcon from 'components/Token/Icon';

import useFetchEarnableForStrategy from '@/hooks/useFetchEarnableForStrategy';

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import 'components/Table/eigen-table.css';

import { AgGridReactProps } from 'ag-grid-react';

import { cn } from '@layr-labs/eigen-kit/util';

import RewardTokenModal from 'components/Rewards/RewardTokenModal';
import CellComponent from 'components/Table/CellComponent';
import { HeaderWithSortIcon } from 'components/Table/HeaderWithSort';

import useAccount from '@/hooks/useAccount';
import useRewardTokenModal from '@/hooks/useRewardTokenModal';
import { useTokens } from '@/hooks/useTokens';
import useUncompletedWithdrawalsForAllStrategies from '@/hooks/useUncompletedWithdrawalsForAllStrategies';
import useUserBalances from '@/hooks/useUserBalances';

import { stakeConfig } from '@/config';

import TokenIconDisplay from './TokenIconDisplay';

const AgGridReact = dynamic<AgGridReactProps<TokenTableData>>(
  () => import('ag-grid-react').then((mod) => mod.AgGridReact),
  {
    ssr: false,
  },
);

type TokenTableProp = {
  page: string;
  tableHeight?: string;
  rowSelection?: 'single' | 'multiple';
  onRowSelected?: (event: RowSelectedEvent<unknown>) => void;
};

const generateColDefs = (isConnected: boolean): ColDef<TokenTableData, unknown>[] => [
  {
    field: 'name',
    headerName: 'Asset',
    resizable: false,
    flex: 2,
    minWidth: 100,
    wrapHeaderText: true,
    suppressMovable: true,
    cellRenderer: (props) => (
      <Link href={`/restake/${props.data.slug}`} className="mx-auto flex h-full items-center gap-2">
        <TokenIcon src={props.data.icon} symbol={props.data.symbol} size={ComponentSize.XS} />
        <TextV2
          intent="TextXS"
          weight="medium"
          className="hidden truncate text-blue-700 underline-offset-1 lg:block"
          dataCypress="dashboardTokenName"
        >
          {props.value}
        </TextV2>
        <TextV2 intent="TextXS" className="text-blue-400 sm:text-blue-400">
          {props.data.symbol}
        </TextV2>
      </Link>
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    resizable: false,
    suppressMovable: true,
    hide: true,
  },
  {
    field: 'balance',
    headerName: 'Wallet Balance',
    resizable: false,
    suppressMovable: true,
    wrapHeaderText: true,
    autoHeight: true,
    valueFormatter: ({ value }) => Number(value as bigint).toString(),
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => (
      <CellComponent
        {...props}
        format="tokenAmount"
        dataCypress={`${props.data.symbol}-balance`}
        showDash={props?.data.symbol === 'ETH'}
      />
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'underlying',
    headerName: 'Restaked Balance',
    resizable: false,
    suppressMovable: true,
    wrapHeaderText: false,
    autoHeight: true,
    width: 150,
    valueFormatter: ({ value }) => Number(value as bigint).toString(),
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => (
      <CellComponent {...props} format="tokenAmount" dataCypress={`${props.data.symbol}-restake`} />
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'tvl',
    headerName: 'Total Value Restaked',
    resizable: false,
    suppressMovable: true,
    wrapHeaderText: false,
    autoHeight: true,
    maxWidth: 180,
    valueFormatter: ({ value }) => Number(value as bigint).toString(),
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => <CellComponent {...props} dataCypress={`${props.data.symbol}-tvl`} />,
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'rewardTokens',
    headerName: 'REWARD TOKENS',
    resizable: false,
    suppressMovable: true,
    wrapHeaderText: false,
    autoHeight: true,
    width: 150,
    comparator: (a: token[], b: token[]) => (a?.length ?? 0) - (b?.length ?? 0),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellClass: 'reward-token-cell',
    cellRenderer: (props) =>
      props.value.length > 0 ? (
        <ButtonV2
          intent="pill"
          size={ComponentSize.XS}
          className="mr-6 flex h-8 items-center justify-center rounded-3xl p-1.5"
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              e.preventDefault();
              props.data.onRewardTokenClick(props.data.symbol, props.data.rewardTokens);
            };
          }}
        >
          <TokenIconDisplay tokenIcons={props.data.rewardTokens.map((t) => t.icon)} />
        </ButtonV2>
      ) : (
        <TextV2 intent="TextXS" className="mr-6 text-blue-400">
          &#45;
        </TextV2>
      ),
    headerComponent: HeaderWithSortIcon,
  },
];

export default function TokenTable({
  page = '',
  tableHeight = 'h-[890px]', // Bespoke number to make the table look good for 20 rows + header
  rowSelection = 'single',
  onRowSelected,
}: TokenTableProp) {
  const [data, setData] = useState<Array<TokenTableData>>(() => []);
  const [sort, setSort] = useLocalStorage<ColumnState | null>(`table-${page}-sort`, null);
  const [filter, setfilter] = useLocalStorage<'All' | 'ETH & LST' | 'Other'>(
    `table-${page}-filter`,
    'All',
  );
  const [searchTerm, setSearchTerm] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gridRef = useRef<GridReadyEvent<TokenTableData, any> | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const router = useRouter();

  const { isConnected } = useAccount();

  const {
    data: { liquidRestakingTokens, nativeRestakingToken },
    isLoading: isTokensLoading,
  } = useTokens();

  const {
    data: { liquidBalances, nativeEthBalance },
    isLoading: isBalancesLoading,
  } = useUserBalances();

  const { data: withdrawalData, isLoading: withdrawalDataIsLoading } =
    useUncompletedWithdrawalsForAllStrategies({});

  const { earnableTokens, isEarnableTokensSuccess, isEarnableTokensLoading } =
    useFetchEarnableForStrategy([
      ...stakeConfig.stakingTokenList.map((token) => token.strategyAddress),
      stakeConfig.nativeToken.strategyAddress,
    ]);

  const {
    isRewardTokenModalOpen,
    rewardTokenModalData,
    toggleRewardTokenModal,
    onRewardTokenClick,
    depositModalButton,
  } = useRewardTokenModal();

  const isLoading =
    isTokensLoading || isBalancesLoading || isEarnableTokensLoading || withdrawalDataIsLoading;

  const tokenTableData: TokenTableData[] = useMemo(() => {
    if (
      !liquidBalances ||
      !isEarnableTokensSuccess ||
      !nativeRestakingToken ||
      !liquidRestakingTokens ||
      withdrawalDataIsLoading
    ) {
      return [];
    }

    const liquidTokensWithBalanceAndRewardTokens: TokenTableData[] = liquidRestakingTokens.map(
      (t, index) => {
        return {
          ...t,
          underlying: t.deposited.underlying,
          balance: liquidBalances[index],
          rewardTokens:
            earnableTokens?.find((et) => et?.strategyAddress === t.strategyAddress.toLowerCase())
              ?.tokenAddresses || [],
          onRewardTokenClick,
        };
      },
    );

    return [
      {
        ...nativeRestakingToken,
        underlying: nativeRestakingToken.deposited.underlying,
        completableWithdrawalAmount:
          withdrawalData?.[nativeRestakingToken.address]?.completableWithdrawalAmount,
        pendingWithdrawalAmount:
          withdrawalData?.[nativeRestakingToken.address]?.pendingWithdrawalAmount,
        pendingWithdrawals: withdrawalData?.[nativeRestakingToken.address]?.pendingWithdrawals,
        balance: nativeEthBalance,
        rewardTokens:
          earnableTokens?.find(
            (et) => et?.strategyAddress === stakeConfig.nativeToken.strategyAddress.toLowerCase(),
          )?.tokenAddresses || [],
        onRewardTokenClick,
      },
      ...liquidTokensWithBalanceAndRewardTokens,
    ];
  }, [
    liquidBalances,
    isEarnableTokensSuccess,
    nativeRestakingToken,
    liquidRestakingTokens,
    nativeEthBalance,
    earnableTokens,
    withdrawalDataIsLoading,
    withdrawalData,
    onRewardTokenClick,
  ]);

  const colDefs = useMemo(() => generateColDefs(isConnected), [isConnected]);

  useEffect(() => {
    if (tokenTableData && !isLoading) {
      setData(tokenTableData);
    }
  }, [tokenTableData, isLoading]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<TokenTableData>): boolean => {
      if (node.data && filter === 'ETH & LST') {
        return node.data.group === 'native' || node.data.group === 'lst';
      }
      if (node.data && filter === 'Other') {
        return node.data.group !== 'native' && node.data.group !== 'lst';
      }
      return true;
    },
    [filter],
  );

  const isExternalFilterPresent = useCallback(() => {
    return filter !== 'All';
  }, [filter]);

  if (isLoading) {
    return (
      <div className="flex h-full flex-col gap-4 rounded-lg bg-white p-2">
        <ValueLoader intent="TextL" className="mt-2 w-1/4" />
        <ValueLoader className={tableHeight} />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col justify-between gap-2 rounded-lg bg-white p-2">
      <RewardTokenModal
        headerTitle={`Reward tokens for ${rewardTokenModalData.symbol}`}
        isOpen={isRewardTokenModalOpen}
        onClose={toggleRewardTokenModal}
        rewardTokenModalTableData={rewardTokenModalData.rewardTokens}
        depositOrDelegateButton={depositModalButton}
      />
      <div className="mx-4 flex flex-col justify-between gap-2 sm:flex-row sm:gap-0">
        <div className="flex items-center justify-around gap-2 sm:justify-normal">
          <ButtonV2
            intent="pill"
            className={cn(
              'font-blue-400 h-full rounded-3xl bg-white px-4 py-2 text-TextS hover:cursor-pointer',
              filter === 'All' && 'bg-blue-100',
            )}
            onClick={() => {
              setfilter('All');
              gridRef.current?.api.paginationGoToPage(0);
            }}
          >
            All
          </ButtonV2>
          <ButtonV2
            intent="pill"
            className={cn(
              'font-blue-400 h-full rounded-3xl bg-white px-4 py-2 text-TextS hover:cursor-pointer',
              filter === 'ETH & LST' && 'bg-blue-100',
            )}
            onClick={() => {
              setfilter('ETH & LST');
              gridRef.current?.api.paginationGoToPage(0);
            }}
          >
            ETH & LSTs
          </ButtonV2>
          <ButtonV2
            intent="pill"
            className={cn(
              'font-blue-400 h-full rounded-3xl bg-white px-4 py-2 text-TextS hover:cursor-pointer',
              filter === 'Other' && 'bg-blue-100',
            )}
            onClick={() => {
              setfilter('Other');
              gridRef.current?.api.paginationGoToPage(0);
            }}
          >
            Other
          </ButtonV2>
        </div>
        <TextInput
          value={searchTerm}
          onChange={(term) => {
            gridRef.current?.api.paginationGoToPage(0);
            setSearchTerm(term);
          }}
          placeholder="Search"
          containerClassName="text-black-300 ml-auto w-full sm:w-60 md:w-60 lg:w-1/4 text-black-300"
          size={ComponentSize.SM}
          dataCypress="token-search"
        />
      </div>
      <div className={`ag-theme-quartz ag-theme-eigen ${tableHeight}`}>
        <AgGridReact
          className={tableHeight}
          rowData={data}
          rowClass="hover:bg-blue-100 cursor-pointer"
          columnDefs={colDefs}
          quickFilterText={searchTerm}
          rowSelection={rowSelection}
          onRowSelected={(event) => {
            if (onRowSelected) {
              onRowSelected(event);
              return;
            }

            const data = event?.data as TokenTableData;
            data?.symbol && router.push(`/restake/${data?.slug}`);
          }}
          onGridReady={(event) => {
            gridRef.current = event;
            if (sort) {
              event.api.applyColumnState({
                state: [sort],
              });
            } else if (isConnected && !sort) {
              event.api.applyColumnState({
                state: [
                  {
                    colId: 'balance',
                    sort: 'desc',
                  },
                ],
              });
            } else {
              event.api.applyColumnState({
                state: [
                  {
                    colId: 'earnedTokens',
                    sort: 'desc',
                  },
                ],
              });
            }

            setCurrentPage(event.api.paginationGetCurrentPage());
            setTotalPages(event.api.paginationGetTotalPages());
          }}
          onSortChanged={(event) => {
            setSort(event.api.getColumnState().find((col) => Boolean(col.sort)) ?? null);
          }}
          suppressRowHoverHighlight={true}
          noRowsOverlayComponent={() => (
            <TextV2 intent="DisplayS" className="mb-4 text-blue-400">
              No assets to display
            </TextV2>
          )}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
          includeHiddenColumnsInQuickFilter
          reactiveCustomComponents
          paginationPageSize={20}
          animateRows={false}
          pagination={true}
          suppressPaginationPanel={true}
          onPaginationChanged={(event) => {
            setCurrentPage(event.api.paginationGetCurrentPage());
            setTotalPages(event.api.paginationGetTotalPages());
          }}
        />
      </div>
      <PaginationV2
        totalPages={totalPages}
        pageIndex={currentPage}
        truncate
        onPageChange={(page) => {
          gridRef.current?.api.paginationGoToPage(page);
        }}
      />
    </div>
  );
}
