import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Address } from 'abitype';

import { getUncompletedWithdrawalsForAllStrategies } from '@/utils/uncompletedWithdrawals';

import useAccount from './useAccount';

export default function useUncompletedWithdrawalsForAllStrategies({
  ...args
}: Omit<UseQueryOptions, 'queryKey' | 'queryFn' | 'enabled' | 'refetchOnMount'> = {}) {
  const { address } = useAccount();
  return useQuery({
    queryKey: ['uncompletedWithdrawalsForWithdrawerForAllTokens', address],
    queryFn: () => {
      if (!address) return Promise.reject({});
      return getUncompletedWithdrawalsForAllStrategies(address as Address);
    },
    enabled: !!address,
    refetchOnMount: 'always',
    ...args,
  });
}
