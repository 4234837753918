import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';

import { ButtonV2 } from '@layr-labs/eigen-kit/react';
import { token } from '@layr-labs/eigen-kit/types';

export default function useRewardTokenModal() {
  const router = useRouter();

  const [isRewardTokenModalOpen, setIsRewardTokenModalOpen] = useState(false);
  const [rewardTokenModalData, setRewardTokenModalData] = useState<{
    symbol: string;
    rewardTokens: Array<token>;
  }>({
    symbol: '',
    rewardTokens: [],
  });

  const toggleRewardTokenModal = useCallback(
    () => setIsRewardTokenModalOpen((prev) => !prev),
    [setIsRewardTokenModalOpen],
  );

  const onRewardTokenClick = useCallback(
    (symbol: string, rewardTokens: Array<token>) => {
      toggleRewardTokenModal();
      setRewardTokenModalData({
        symbol,
        rewardTokens,
      });
    },
    [toggleRewardTokenModal, setRewardTokenModalData],
  );

  const depositModalButton = useMemo(() => {
    return (
      <ButtonV2
        intent="primary"
        className="w-full uppercase"
        onClick={() =>
          rewardTokenModalData.symbol === 'ETH'
            ? router.push(`/restake/ETH`)
            : router.push(`/restake/${rewardTokenModalData.symbol}?action=deposit`)
        }
      >
        {`Restake ${rewardTokenModalData.symbol}`}
      </ButtonV2>
    );
  }, [rewardTokenModalData.symbol, router]);

  return {
    isRewardTokenModalOpen,
    setIsRewardTokenModalOpen,
    rewardTokenModalData,
    setRewardTokenModalData,
    toggleRewardTokenModal,
    onRewardTokenClick,
    depositModalButton,
  };
}
