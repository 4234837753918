import { Fragment, memo } from 'react';

import { NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';

import TokenIcon from 'components/Token/Icon';

import { tempAPRMax } from '@/utils/rewardsUtils';

export const TokenIconDisplay = memo(
  ({
    tokenIcons: _tokenIcons,
    apr,
    className,
  }: {
    tokenIcons: string[];
    apr?: number;
    className?: string;
  }) => {
    let countText = '';
    if (_tokenIcons.length === 0) {
      return null;
    }
    let tokenIcons = [..._tokenIcons];

    if (tokenIcons.length > 3) {
      countText = `+${tokenIcons.length - 3}`;
      tokenIcons = tokenIcons.slice(0, 3);
    }

    return (
      <div className="flex h-6 items-center gap-2">
        <div
          className={cn('relative h-6', className)}
          style={{ width: 24 + (Math.min(_tokenIcons.length, 4) - 1) * 8 }}
        >
          {tokenIcons.map((icon, index) => (
            <div key={index}>
              {icon !== '' ? (
                <TokenIcon
                  src={icon}
                  fill={false}
                  className="absolute h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-100"
                  style={{ left: `${index * 8}px` }}
                />
              ) : (
                <div
                  className="absolute h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-300"
                  style={{ left: `${index * 8}px` }}
                />
              )}
            </div>
          ))}
          {countText && (
            <div key="count" className="absolute" style={{ left: `${tokenIcons.length * 8}px` }}>
              <TextV2
                intent="TextXS"
                className="absolute flex h-6 w-6 items-center justify-center rounded-full border-2 border-blue-100 bg-white text-blue-400"
              >
                {countText}
              </TextV2>
            </div>
          )}
        </div>
        {apr ? (
          <TextV2 intent="TextXS" className="ml-1 text-blue-800">
            {tempAPRMax(apr) ? (
              <Fragment>
                <NumberDisplay value={Number(apr) * 100} decimals={4} format="tokenAmount" />%
              </Fragment>
            ) : (
              'N/A'
            )}
          </TextV2>
        ) : null}
      </div>
    );
  },
);

TokenIconDisplay.displayName = 'TokenIconDisplay';

export default TokenIconDisplay;
