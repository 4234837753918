import { Address } from 'viem';

import { TextV2 } from '@layr-labs/eigen-kit/react';

import NavLink from 'components/Layout/NavLink';
import TokenIcon from 'components/Token/Icon';

import { RewardsMode } from './ClaimModal';

import { env } from '@env';

function NameSymbolCell({
  icon,
  tokenAddress,
  name,
  symbol,
  rewardsMode = 'select',
}: {
  icon: string;
  tokenAddress: Address;
  name?: string;
  symbol?: string;
  rewardsMode: RewardsMode;
}) {
  return (
    <div className={`flex items-center gap-1 ${rewardsMode === 'select' ? '' : 'py-2'}`}>
      {icon !== '' ? (
        <TokenIcon
          src={icon}
          fill={false}
          className="h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-100"
        />
      ) : (
        <div className="h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-300" />
      )}
      <NavLink
        isExternalLink={true}
        href={`${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/address/${tokenAddress}`}
        className="hidden items-center gap-1 font-abcRepro text-TextXS text-blue-700 underline-offset-1 hover:underline md:flex"
      >
        <TextV2
          intent="TextXS"
          weight="medium"
          className="hidden text-blue-700 underline-offset-1 lg:block"
          dataCypress="dashboardTokenName"
        >
          {name ?? tokenAddress}
        </TextV2>
        {symbol && (
          <TextV2 intent="TextXS" className="text-blue-400">
            {symbol}
          </TextV2>
        )}
      </NavLink>
    </div>
  );
}

export default NameSymbolCell;
