import { useMemo } from 'react';

import { Address, erc20Abi } from 'viem';
import { useBalance, useReadContracts } from 'wagmi';

import { api } from '@/utils/api';

import useAccount from './useAccount';

export default function useUserBalances() {
  const { address, isConnected } = useAccount();

  const { data: tokens = [] } = api.token.getTokens.useQuery({
    include_native: false,
  });

  const { data: liquidBalances, isLoading: isLiquidBalancesLoading } = useReadContracts({
    allowFailure: false,
    contracts: tokens.map((token) => ({
      address: token.address,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: address ? [address] : undefined,
    })),
    query: {
      enabled: isConnected && tokens.length > 0,
    },
  });

  const { data: { value: nativeEthBalance } = { value: 0n }, isLoading: isNativeBalanceLoading } =
    useBalance({
      address: address as Address,
      query: {
        enabled: isConnected,
      },
    });

  const memoizedLiquidBalances = useMemo(() => {
    return liquidBalances ? liquidBalances : tokens.map(() => 0n);
  }, [liquidBalances, tokens]);

  return {
    data: {
      liquidBalances: memoizedLiquidBalances,
      nativeEthBalance,
    },
    isLoading: isLiquidBalancesLoading || isNativeBalanceLoading,
    isLiquidBalancesLoading,
    isNativeBalanceLoading,
  };
}
