'use client';

import { NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';

function CellComponent({
  value = 0,
  format = 'compact',
  decimals = 2,
  dataCypress,
  showDash = false,
}: {
  value: number | bigint;
  format?: 'compact' | 'tokenAmount' | 'percentage';
  decimals?: number;
  dataCypress?: string;
  showDash?: boolean;
}) {
  const color = Number(value) ? 'text-blue-700' : 'text-blue-400';

  return (
    <TextV2 intent="TextXS" className={cn('mr-6', color)} dataCypress={dataCypress}>
      {showDash ? (
        '\u2011'
      ) : (
        <NumberDisplay value={value || 0} format={format} decimals={Number(value) ? decimals : 0} />
      )}
    </TextV2>
  );
}

export default CellComponent;
